<template>
    <admin-dashboard-layout>
        <template v-slot:left-tools>
            <timeframe-select/>
        </template>
        <template v-slot:right-tools>
            <v-btn @click="$router.push({name: 'admin.price-plans.create'})" v-text="'Create'"/>
        </template>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="pricePlans"
            :items-per-page="5"
            class="elevation-1"
            @click:row="handleClickRow"
        >
            <template v-slot:item.created_at="{ item }">
                {{ item.created_at | formattedDate }}
            </template>
            <template v-slot:item.actions="{ item }">
                <delete-dialog :model="item" type="price-plans" @success="init"/>
            </template>
        </v-data-table>
    </admin-dashboard-layout>
</template>

<script>
    import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
    import PricePlan from "../../../models/PricePlan";
    import DeleteDialog from "@/components/dialogs/DeleteDialog";
    import TimeframeSelect from "@/views/partials/TimeframeSelect";

    export default {
        name: "users-index",
        components: {TimeframeSelect, DeleteDialog, AdminDashboardLayout},
        data: function () {
            return {
                pricePlans: [],
                loading: false,
                headers: [
                    {
                        text: '# ID',
                        align: 'start',
                        sortable: false,
                        value: 'id',
                    },
                    { text: 'Name', value: 'name' },
                    { text: 'Actions', value: 'actions' },
                ],
            }
        },
        methods: {
            handleClickRow(plan) {
                this.$router.push({name: 'admin.price-plans.edit', params: {id: plan.id}})
            },
            async init() {
                this.loading = true
                await this.loadTable()
                this.loading = false
            },
            async loadTable() {
                let query = PricePlan;

                if (this.$route.query.lastDays) {
                    query = query.where('lastDays', this.$route.query.lastDays);
                }

                this.pricePlans = await query.get()
            }
        },
        async mounted() {
            await this.init();
        },
        watch: {
            "$route.query": async function (val, oldVal) {
                if (val !== oldVal) {
                    await this.init()
                }
            }
        }
    }
</script>

<style scoped>

</style>
